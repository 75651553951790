import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import CIInsightsIcon from '../../assets/images/icons/ci-insights.svg'
import WIPicon from '../../assets/images/icons/working-agreements.svg'
import ReviewRateIcon from '../../assets/images/product/blurb-icons/all-work.png'
import PRIcon from '../../assets/images/product/blurb-icons/fast.svg'
import SizeIcon from '../../assets/images/product/blurb-icons/gear.png'
import ClockIcon from '../../assets/images/product/blurb-icons/lock.png'
import InvestmentDistributionIcon from '../../assets/images/product/blurb-icons/slide-and-dice.png'
import TrendsIcon from '../../assets/images/product/blurb-icons/trends.png'
import BugIcon from '../../assets/images/product/blurb-icons/zoom.png'
import MetaImage from '../../assets/images/meta/engineering-metrics.png'
import tag from '../../assets/images/tags/engineering-metrics.svg'
import Layout from '../../components/Layout'
import RoleCarousel from '../../components/ProductCarousel/RoleCarousel'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import ToolsBlock from '../../components/blocks/ToolsBlock'
import Block from '../../components/primitives/Block'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import CTABlock from '../../components/blocks/CTABlock'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/insights/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    surveys: file(relativePath: { eq: "images/product/surveys/question.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    dora: file(relativePath: { eq: "images/blurbs/dora-670.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    space: file(
      relativePath: { eq: "images/product/overview/investment-balance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
  }
`

const MetricsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Engineering metrics for modern software organizations"
      variant="dark"
      isNew
      description="Swarmia surfaces research-backed engineering metrics, including DORA and SPACE, and insights that guide software organizations to take concrete action."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          tag={tag}
          title={
            <>
              Measure the right things at every&nbsp;level of the organization
            </>
          }
          content="Improve engineering productivity and developer experience by tracking a balanced set of research-backed engineering metrics."
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <Box
          backgroundColor="black"
          paddingY={40}
          paddingX={{ xs: 20, md: 36 }}
        >
          <DarkLogoBlock hideBackground />
        </Box>
        <LeadBlock
          heading="Relevant metrics for every role"
          content="The data a VPE needs to do their job is very different from the information a software engineer requires to do theirs. Swarmia caters to both levels — and everything in between."
        />
        <Block
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(64)}
          maxWidth={1220}
        >
          <RoleCarousel />
        </Block>
        <QuoteBlock person="silvia" />
        <LeadBlock
          heading="Out-of-the-box support for DORA & SPACE"
          content="When you’re just getting started on your developer productivity journey, DORA and SPACE are often great places to start. Swarmia makes tracking both a breeze."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(92)}
          columns={[
            <Blurb
              key="1"
              title="DORA metrics"
              text="Get immediate visibility into DORA metrics: deployment frequency, change lead time, change failure rate, and mean time to recovery."
              href="/dora-metrics/"
              target="_self"
              image={getImage(data.dora)}
              isLarge
            />,
            <Blurb
              key="2"
              title="SPACE framework"
              text="Get a holistic picture of developer productivity by measuring all five aspects of the SPACE framework."
              href="/space/"
              target="_self"
              image={getImage(data.space)}
              isLarge
            />,
          ]}
        />
        <QuoteBlock person="walterDora" />
        <LeadBlock
          heading="Here are some of the metrics you can track with Swarmia"
          content="Swarmia only surfaces measures with a proven correlation to business outcomes, developer productivity, and developer experience."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          columns={[
            <Blurb
              key="1"
              title="PR cycle time"
              text="Get pull requests through faster by eliminating common bottlenecks."
              icon={PRIcon}
            />,
            <Blurb
              key="2"
              title="Deployment frequency"
              text="Keep track of how often your teams are deploying code to production."
              icon={TrendsIcon}
            />,
            <Blurb
              key="3"
              title="Investment distribution"
              text="Understand where engineering time goes and make better prioritization decisions."
              icon={InvestmentDistributionIcon}
            />,
          ]}
        />
        <ColumnsBlock
          paddingBottom={responsiveScale(32)}
          columns={[
            <Blurb
              key="1"
              title="Bug insights"
              text="Stay on top of the bugs your team is working on and the time it takes to fix them."
              icon={BugIcon}
            />,
            <Blurb
              key="2"
              title="Batch size"
              text="Roll out features to your customers faster and with less risk."
              icon={SizeIcon}
            />,
            <Blurb
              key="3"
              title="CI insights"
              text="Troubleshoot pipeline issues to reduce CI wait times and costs."
              icon={CIInsightsIcon}
            />,
          ]}
        />
        <ColumnsBlock
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Review time"
              text="Identify review bottlenecks and get your work merged faster."
              icon={ClockIcon}
            />,
            <Blurb
              key="2"
              title="Review rate"
              text="Maintain high code quality by maximizing the percentage of reviewed code."
              icon={ReviewRateIcon}
            />,
            <Blurb
              key="3"
              title="WIP limits"
              text="Allow teams to set their own work-in-progress limits to gain focus."
              icon={WIPicon}
            />,
          ]}
        />
        <QuoteBlock person="alex" />
        <LeadBlock
          heading="Metrics alone don’t drive change"
          content="Improve developer productivity in practice by combining engineering metrics with actionable targets, Slack notifications, and developer experience surveys."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              image={getImage(data.workingAgreement1)}
              title="Adopt working agreements"
              text="Work with your team to set relevant and achievable targets."
              href="/product/working-agreements/"
              target="_self"
            />,
            <Blurb
              key="2"
              image={getImage(data.workingAgreement2)}
              title="Automate feedback loops"
              text="Get real-time notifications in Slack to keep up with your agreements."
              href="/github-slack-integration/"
              target="_self"
            />,
            <Blurb
              key="3"
              image={getImage(data.surveys)}
              title="Improve developer experience"
              text="Run recurring developer experience surveys to continuously improve."
              href="/product/surveys/"
              target="_self"
            />,
          ]}
        />
        <ToolsBlock />
        <CTABlock title="Start improving developer productivity and experience today." />
      </GlobalStyleReset>
    </Layout>
  )
}

export default MetricsPage
